import { DispatchTypeOrderBuddy } from '~/types/orderbuddy-types'
import { checkout } from '~/store/checkout'
export function getTargetDispatchType (path: string) {
    return path.endsWith(('shop')) ? DispatchTypeOrderBuddy.DELIVER : DispatchTypeOrderBuddy.PICK_UP
}
export async function navigateToDispatchType (name: DispatchTypeOrderBuddy) {
    const route = useRoute()
    if (!route.path.includes('checkout')) {
        await navigateTo(getDistpatchTypeUrl(name))
    } else {
        await checkout().setDispatchType(name, true)
    }
}

export function getDistpatchTypeUrl (name: DispatchTypeOrderBuddy) {
    const localePath = useLocalePath()
    if (name === DispatchTypeOrderBuddy.DELIVER) {
        return localePath('/shop')
    } else {
        return localePath('/shop/pickup')
    }
}
